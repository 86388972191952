'use strict';

define('sw/private/cacheManager',['vbc/private/log'], (Log) => {
  const logger = Log.getLogger('/sw/private/CacheManager');

  // TODO: once we move to ES Modules and module service worker, this class can be combined with DynamicCacheManager
  // defined in init.js IIFE
  // https://jira.oraclecorp.com/jira/browse/VBS-8594
  class CacheManager {
    /**
     *
     * @param {Object} dynamicCacheManager
     */
    constructor(dynamicCacheManager) {
      // Once asynchronous loading restriction is removed, these two classes can be combined
      this.dynamicCacheManager = dynamicCacheManager;
    }

    /**
     * Allows to increase message visibility during debugging by using a warning level in DEBUG mode and info level
     * otherwise.
     * @param {*} message logging message
     */
    log(message) {
      return (this.dynamicCacheManager.config.DEBUG) ? logger.warn(message) : logger.info(message);
    }

    /**
     * @param {string} cacheName the name of the cache to check
     * @returns {boolean} true, if the cache should be deleted during service worker cleanup during activation
     * lifecycle phase, because it is considered stale. Dynamic caches are considered stale if they stored
     * artifacts with an older version (version is captured in cache name). Precache is considered stale only
     * when an application has explicitly disabled caching. In all other cases, precache controller handles
     * stale entries by using revision info.
     */
    shouldDelete(cacheName) {
      logger.info(`Checking cache ${cacheName}`);
      let shouldDelete = false;
      const cachingDisabled = !this.dynamicCacheManager.swConfig.isCachingEnabled();
      const dynamicCachingDisabled = !this.dynamicCacheManager.swConfig.isDynamicCachingEnabled();

      // This logic assumes that we're not changing names of dynamic caches. If we ever do that, we need to handle
      // cleanup in another way.
      const currentCaches = this.dynamicCacheManager.getCurrentCacheNames();
      const vbCacheRegExp = this.dynamicCacheManager.getCurrentCachePrefixesRegExp();
      // VB managed dynamic caches should be deleted if they correspond to old versions, or dynamic caching is disabled
      if (vbCacheRegExp.test(cacheName)) {
        shouldDelete = !currentCaches.includes(cacheName) || dynamicCachingDisabled;
      } else if (cacheName === this.dynamicCacheManager.precacheName) {
        // workbox precache should only be deleted when caching is disabled
        shouldDelete = cachingDisabled;
      }
      logger.info(`Cache ${cacheName} will${shouldDelete ? '' : ' not'} be deleted`);
      return shouldDelete;
    }

    async deleteStaleCaches() {
      // expiration plugin handles deletion of the caches it is associated with
      // eslint-disable-next-line max-len
      // see https://developer.chrome.com/docs/workbox/reference/workbox-expiration/#type-ExpirationPlugin
      if (this.dynamicCacheManager.expirationPlugin) {
        await this.dynamicCacheManager.expirationPlugin.deleteCacheAndMetadata();
      }

      logger.info(`Attempting to delete stale caches with prefix ${this.dynamicCacheManager.prefix}`);
      const cacheNames = await caches.keys();
      const cachesToDelete = cacheNames.filter((n) => this.shouldDelete(n));
      const deletePromises = [];

      cachesToDelete.forEach((c) => {
        this.log(`Deleting cache ${c}`);
        deletePromises.push(caches.delete(c));
      });
      await Promise.all(deletePromises);
    }
  }
  return CacheManager;
});

