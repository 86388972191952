'use strict';

/**
 * Used by requirejs/Durandal to combine service worker modules into a single file, sw-modules.js.
 * Strictly speaking, not all files included in sw-modules are AMD modules. cacheManager.js is, but init.js is not.
 * Therefore, sw-modules.js needs to be loaded in a specific order, after versions.js, and after workbox.
 * In context of the service worker, this is enforced by the order of importScript statements.
 * In context of AMD modules and the unit tests, it is enforced by order of requirejs calls.
 */
requirejs.config({
  map: {
    '*': {
      main: 'sw/main',
    },
  },
});

define("sw/main", function(){});

